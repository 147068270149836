import React from 'react';
import { kebabCase } from 'lodash';

const MdPureTextComponent = ({frontmatter, html}) => {
  let { title } = frontmatter

  return (
    <section className="mx-auto container md:flex lg:flex-row pb-4 border-b border-gray-3 00 md:pt-20 md:-mt-12 " id={ kebabCase(title) }>
      { title && (<h1 className={`md:w-1/4 sm:pr-16 mobileVertical:mb-4 mobileVertical:mt-4 md:mt-0 md:mb-0 ${frontmatter.sectionHeaderClass}`} >
          {title}
        </h1>)
      }
      <div className={ (title && "md:w-3/4") }>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          className="markdown md-pure-text-component-text"
        />
      </div>
    </section>
  );
};

export default MdPureTextComponent;

