// type: pageSectionWithImage

import React from 'react';
import { kebabCase } from 'lodash'
import { useMediaQuery } from 'react-responsive'

const MdCardComponent = ({frontmatter, html}) => {
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 1023px)'
  });

  let { image, title, imageWidth, imagePosition, imageBelowHeader, linkSectionWithImageBelow, extraImageClass,
        extraContainerClass } = frontmatter;

  const imagePositionBottom = imagePosition === 'bottom'

  // purposely generated as whole string classes so that
  // that they get picked up for tailwinds css generators
  // todo find a better way to do this, or move this mapping to a dynamically generate place
  // this is due to tailwind (purgecss?) limitations on dyncamically generated strings
  // works in dev but no in prod... https://tailwindcss.com/docs/controlling-file-size/#writing-purgeable-html
  // yes this is somewhat hacky and should be abstracted if we use this pattern going
  // forward but it doesnt have an impact on the final product

  const imageWidthGenerator = (imageWidth) => (
    {
      '1/2': 'w-1/2',
      '1/5': 'w-1/5',
      '2/5': 'w-2/5',
      '3/5': 'w-3/5',
      '4/5': 'w-4/5'
    }[imageWidth] || ''
  );

  // in develop mode remove .default => todo resolve in config
  const images = require.context('../images');
  let imageToRender = images('./' + image).default;

  // generate image classes
  let generatedImageClasses = () => {
    // bottom or side
    let imageClasses = imagePositionBottom ? 'md-card-image-bottom' : 'md-card-image';
    // custom width
    imageClasses = imageClasses.concat(' ', imageWidthGenerator(imageWidth))
    if (!!imageWidthGenerator(imageWidth)) { imageClasses = imageClasses.concat(' ', imageWidthGenerator(imageWidth)) }
    if (imageBelowHeader) { imageClasses = imageClasses.concat('sm:pt-10 lg:pt-20', ' ')  }
    if (!!extraImageClass) { imageClasses = imageClasses.concat(' ', extraImageClass)  }

    return imageClasses
  }

  return (
    <section
      className={
        `mx-auto container 
        ${!imagePositionBottom && 'flex'} 
        lg:flex-row border-gray-3 
        00 pt-12 -mt-12
        ${(!linkSectionWithImageBelow || isMobileDevice) && 'border-b-2 mb-6'}
        ${isMobileDevice && 'pb-6'}  
        ${extraContainerClass && extraContainerClass}
      
      `}
      id={ kebabCase(title) }
    >
      <div className={`${!imagePositionBottom && 'lg:w-1/2'} pr-4`}
      >
        <h1 className='section-header pt-4 xl:pt-10'>{title}</h1>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          className="markdown"
        />
      </div>

      {(!isMobileDevice || imagePositionBottom) &&
      <div className={`${imagePositionBottom ? '' : 'hidden lg:block w-1/2'}`}>
        <img className={generatedImageClasses()}
             src={imageToRender}/>
      </div>
      }

    </section>
  );
};

export default MdCardComponent;

