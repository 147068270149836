import React from 'react';
import MdCardComponent from './MdCardComponent';
import MdPureTextComponent from './MdPureTextComponent';
import { kebabCase } from 'lodash';

const MdCardsList = ({nodes}) => {

  return (
      nodes.map((node) => {
        if (node.frontmatter.type === 'pageSectionPureText') {
          return(<MdPureTextComponent key={kebabCase(node.frontmatter.title)} {...node} />)
        } else {
          return(<MdCardComponent key={kebabCase(node.frontmatter.title)} {...node} />)
        }
      })
  )
};

export default MdCardsList;
