import React, { Component, PureComponent } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import leftArrow from './../images/icons/left-arrow-32-px.png'
import rightArrow from './../images/icons/right-arrow-32-px.png'

import { debounce, kebabCase } from 'lodash';

const DynamicCategories = ({nodes}) => {
  return (
    nodes.map((node) =>
      <li key={kebabCase(node.frontmatter.title).concat('-list-node')}>
        <AnchorLink
          key={kebabCase(node.frontmatter.title)}
          className="mx-4 text-gray-900 sticky-navbar-item"
          href={`#${kebabCase(node.frontmatter.title)}`}
        >
          {node.frontmatter.title}
        </AnchorLink>
      </li>
    )
  );
};

class IndexStickyHeader extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      hasOverflow: false,
      canScrollLeft: false,
      canScrollRight: false
    }

    this.checkForOverflow = this.checkForOverflow.bind(this)
    this.checkForScrollPosition = this.checkForScrollPosition.bind(this)

    this.debounceCheckForOverflow = debounce(this.checkForOverflow, 1000)
    this.debounceCheckForScrollPosition = debounce(
      this.checkForScrollPosition,
      200
    )

    this.container = null;
  }

  componentDidMount() {
    // short circuit mount logic if no sticky header
    if (this.container) {
      this.checkForOverflow();
      this.checkForScrollPosition();

      this.container.addEventListener(
        'scroll',
        this.debounceCheckForScrollPosition
      )
    }
  }

  scrollContainerBy(distance) {
    this.container.scrollBy({ left: distance, behavior: 'smooth' })
  }

  checkForOverflow() {
    const { scrollWidth, clientWidth } = this.container
    const hasOverflow = scrollWidth > clientWidth + 12;

    this.setState({ hasOverflow });
  }

  scrollLeftButton() {
    const { canScrollLeft, hasOverflow } = this.state
    if (!hasOverflow) {
      return (null)
    } else {
      return (
        <img className='sticky-scroll-button-left'
             type="button"
             disabled={!canScrollLeft}
             onClick={() => {
               this.scrollContainerBy(-200)
             }}
             src={leftArrow} />
        )
    }
  }

  scrollRightButton() {
    const { canScrollRight, hasOverflow } = this.state;

    if (!hasOverflow) {
      return (null)
    } else {
      return (
          <img className='sticky-scroll-button-right'
             src={rightArrow}
             type="button"
             disabled={!canScrollRight}

             onClick={() => {
               this.scrollContainerBy(200)
             }}
          />
        )
      }
    }

  componentWillUnmount() {
    if (this.container) {
      this.container.removeEventListener(
        'scroll',
        this.debounceCheckForScrollPosition
      )
      this.debounceCheckForOverflow.cancel()
    }
  }

  checkForScrollPosition() {
    const { scrollLeft, scrollWidth, clientWidth } = this.container

    this.setState({
      canScrollLeft: scrollLeft > 0,
      canScrollRight: scrollLeft !== scrollWidth - clientWidth
    })
  }

  render() {
    // dont render sticky header unless more then 1 section
    if (this.props.nodes.length < 2) { return null }

    return (
      <>
        <header className="sticky-navbar-parent-container sticky top-0 bg-white shadow z-10">
        <ul
          className="sticky-navbar-container flex flex-col sm:flex-row justify-between items-center mx-auto py-1"
          ref={node => {
            this.container = node
          }}
        >
          {this.scrollLeftButton()}
            {<DynamicCategories nodes={this.props.nodes}/>}
          {this.scrollRightButton()}
        </ul>
        </header>
      </>
    )
  }
}

export default IndexStickyHeader;
